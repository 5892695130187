.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #E5332A;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-weight: bolder;
  color:white;
}

.App-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formularioDispositivo{
  min-width: 250px;
  padding: 20px;
}

.inputFieldContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0;
}

.labelForm{
  font-size: small;
  margin: 0;
  padding: 0;

}

.inputField{
  width: 100%;
  margin: 0;
  font-size: 16px;
  padding: 5px 0px;
  box-sizing: border-box;
}

.botonesContainer{
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}