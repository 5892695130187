@media (min-width: 400px) {
    .containerForm {
        grid-template-columns: 50% 50%;
    }
}

@media (max-width: 399px) {
    .containerForm {
        grid-template-columns: 100%;
    }
}